import { GetObjectCommand, S3Client } from '@aws-sdk/client-s3'
import { getSignedUrl } from '@aws-sdk/s3-request-presigner'
import { ErrorBoundary } from '@sentry/react'
import { OntologyType, Role } from 'common/types'
import React, { useEffect, useState } from 'react'
import { Centered } from 'shared/components/Centered'
import {
  AsyncState,
  dataState,
  errorState,
  loadingState,
} from 'shared/types/asyncState'
import { s3BucketName, s3BucketRegion } from 'shared/types/aws'
import { Button } from './components'
import { auth } from './firebase'
import { get } from './firebaseMethods'
import { useAuth } from './hooks/useAuth'
import { Labeling } from './Labeling'
import { LabelingBootcamp } from './LabelingBootcamp'
import { LabelingExpert } from './LabelingExpert'
import { Listener } from './Listener'
import { Login } from './Login'
import { Review } from './Review'
import { Stats } from './Stats'

type GetUrl = (path: string) => Promise<string>

type BucketConfig = {
  name: string
  region: string
}

const defaultBucketConfig = {
  name: s3BucketName,
  region: s3BucketRegion,
}

const bucketConfigMap: Record<OntologyType, BucketConfig> = {
  layer1: defaultBucketConfig,
  respiration: {
    name: 'oso-pneumo-aphp',
    region: 'eu-west-3',
  },
  demo: {
    name: 'oso-labels-sandbox',
    region: 'eu-west-3',
  },
  keyword: defaultBucketConfig,
  unet: defaultBucketConfig,
}

export const App: React.FC = () => {
  return (
    <React.StrictMode>
      <ErrorBoundary
        fallback={
          <div className="flex min-h-screen flex-col items-center justify-center">
            <div className="py-4">Une erreur est survenue</div>
            <Button
              onClick={() => {
                window.location.reload()
              }}
            >
              Rafraîchir
            </Button>
          </div>
        }
      >
        <Content />
      </ErrorBoundary>
    </React.StrictMode>
  )
}

const Content: React.FC = () => {
  const { data, loading, error } = useAuth()

  return (
    <div className="flex min-h-screen w-screen items-center justify-center text-[calc(10px+1.5vmin)]">
      {loading ? (
        <h2>Authentification...</h2>
      ) : error ? (
        <h2>Erreur</h2>
      ) : data === null ? (
        <Login />
      ) : (
        <AuthentifiedContent />
      )}
      {import.meta.env.MODE === 'staging' && (
        <div className="absolute right-4 top-4 flex rounded-full bg-green-600 px-2 py-1 text-sm font-bold uppercase">
          Staging
        </div>
      )}
    </div>
  )
}

const AuthentifiedContent: React.FC = () => {
  const [{ data: getUrl, loading, error }, setGetUrl] =
    useState<AsyncState<GetUrl>>(loadingState())

  useEffect(() => {
    async function getCredentials() {
      const credentials = await get('connections/aws').catch((error) =>
        console.log(error),
      )

      if (!credentials) {
        setGetUrl(errorState(new Error('Invalid credentials')))
        return
      }

      const { accessKeyId, secretAccessKey } = credentials

      const s3Client = new S3Client({
        credentials: { accessKeyId, secretAccessKey },
        region: bucketConfigMap[import.meta.env.VITE_ONTOLOGY].region,
      })

      const getUrl = (key: string) => {
        const command = new GetObjectCommand({
          Bucket: bucketConfigMap[import.meta.env.VITE_ONTOLOGY].name,
          Key: key,
        })
        return getSignedUrl(s3Client, command, { expiresIn: 3600 })
      }

      setGetUrl(dataState(getUrl))
    }

    getCredentials()
  }, [])

  return loading ? (
    <h2>Autorisation...</h2>
  ) : error ? (
    <div>
      <h2>Votre compte a bien été créé</h2>
      <h3>Prévenez l'administrateur pour continuer</h3>
    </div>
  ) : getUrl === null ? (
    <h2>Erreur</h2> // Should not be possible, bad type inference
  ) : (
    <AuthorizedContent getUrl={getUrl} />
  )
}

const AuthorizedContent = ({ getUrl }: { getUrl: GetUrl }) => {
  const showListener = window.location.href.indexOf('?listen') >= 0
  const showReview = window.location.href.indexOf('?review') >= 0
  const showStats = window.location.href.indexOf('?stats') >= 0
  const [role, setRole] = useState<Role>()
  const [isLabelingEnable, setLabelingEnable] = useState(
    window.location.pathname === '/',
  )

  useEffect(() => {
    async function runEffect() {
      const user = auth.currentUser
      const role = await get(`users/${user?.uid}/role`)
      setRole(role)
    }

    runEffect()
  }, [])

  const enableLabeling = () => setLabelingEnable(true)

  if (!role) return <Centered>Role non attribué</Centered>
  if (showReview) return <Review getUrl={getUrl} />
  if (!showListener) {
    if (showStats) return <Stats />
    if (isLabelingEnable && (role === 'OSO' || role === 'External'))
      return <Labeling getUrl={getUrl} />
    if (isLabelingEnable && role === 'Bootcamp')
      return <LabelingBootcamp getUrl={getUrl} />
    if (isLabelingEnable && role === 'Expert')
      return <LabelingExpert getUrl={getUrl} />
  }

  return <Listener getUrl={getUrl} enableLabeling={enableLabeling} />
}
